// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-books-how-to-take-smart-notes-mdx": () => import("./../../../src/pages/books/how-to-take-smart-notes.mdx" /* webpackChunkName: "component---src-pages-books-how-to-take-smart-notes-mdx" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-books-unshakeable-mdx": () => import("./../../../src/pages/books/unshakeable.mdx" /* webpackChunkName: "component---src-pages-books-unshakeable-mdx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-digital-garden-a-decisive-moment-mdx": () => import("./../../../src/pages/digital-garden/a-decisive-moment.mdx" /* webpackChunkName: "component---src-pages-digital-garden-a-decisive-moment-mdx" */),
  "component---src-pages-digital-garden-caching-problems-mdx": () => import("./../../../src/pages/digital-garden/caching-problems.mdx" /* webpackChunkName: "component---src-pages-digital-garden-caching-problems-mdx" */),
  "component---src-pages-digital-garden-command-line-shortcuts-mdx": () => import("./../../../src/pages/digital-garden/command-line-shortcuts.mdx" /* webpackChunkName: "component---src-pages-digital-garden-command-line-shortcuts-mdx" */),
  "component---src-pages-digital-garden-debugging-mdx": () => import("./../../../src/pages/digital-garden/debugging.mdx" /* webpackChunkName: "component---src-pages-digital-garden-debugging-mdx" */),
  "component---src-pages-digital-garden-embrace-being-stuck-mdx": () => import("./../../../src/pages/digital-garden/embrace-being-stuck.mdx" /* webpackChunkName: "component---src-pages-digital-garden-embrace-being-stuck-mdx" */),
  "component---src-pages-digital-garden-genesis-mdx": () => import("./../../../src/pages/digital-garden/genesis.mdx" /* webpackChunkName: "component---src-pages-digital-garden-genesis-mdx" */),
  "component---src-pages-digital-garden-getting-started-with-open-source-mdx": () => import("./../../../src/pages/digital-garden/getting-started-with-open-source.mdx" /* webpackChunkName: "component---src-pages-digital-garden-getting-started-with-open-source-mdx" */),
  "component---src-pages-digital-garden-git-understanding-version-control-mdx": () => import("./../../../src/pages/digital-garden/git-understanding-version-control.mdx" /* webpackChunkName: "component---src-pages-digital-garden-git-understanding-version-control-mdx" */),
  "component---src-pages-digital-garden-having-productive-breaks-mdx": () => import("./../../../src/pages/digital-garden/having-productive-breaks.mdx" /* webpackChunkName: "component---src-pages-digital-garden-having-productive-breaks-mdx" */),
  "component---src-pages-digital-garden-introduction-to-the-shell-mdx": () => import("./../../../src/pages/digital-garden/introduction-to-the-shell.mdx" /* webpackChunkName: "component---src-pages-digital-garden-introduction-to-the-shell-mdx" */),
  "component---src-pages-digital-garden-js": () => import("./../../../src/pages/digital-garden.js" /* webpackChunkName: "component---src-pages-digital-garden-js" */),
  "component---src-pages-digital-garden-moving-to-windows-mdx": () => import("./../../../src/pages/digital-garden/moving-to-windows.mdx" /* webpackChunkName: "component---src-pages-digital-garden-moving-to-windows-mdx" */),
  "component---src-pages-digital-garden-put-it-in-the-calendar-mdx": () => import("./../../../src/pages/digital-garden/put-it-in-the-calendar.mdx" /* webpackChunkName: "component---src-pages-digital-garden-put-it-in-the-calendar-mdx" */),
  "component---src-pages-digital-garden-setting-up-windows-terminal-mdx": () => import("./../../../src/pages/digital-garden/setting-up-windows-terminal.mdx" /* webpackChunkName: "component---src-pages-digital-garden-setting-up-windows-terminal-mdx" */),
  "component---src-pages-digital-garden-successfull-interview-mdx": () => import("./../../../src/pages/digital-garden/successfull-interview.mdx" /* webpackChunkName: "component---src-pages-digital-garden-successfull-interview-mdx" */),
  "component---src-pages-digital-garden-tutorial-hell-mdx": () => import("./../../../src/pages/digital-garden/tutorial-hell.mdx" /* webpackChunkName: "component---src-pages-digital-garden-tutorial-hell-mdx" */),
  "component---src-pages-digital-garden-vim-commands-mdx": () => import("./../../../src/pages/digital-garden/vim-commands.mdx" /* webpackChunkName: "component---src-pages-digital-garden-vim-commands-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

